import React from 'react'
//import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'

import './layout.css';
//title={data.site.siteMetadata.title}
const Layout = ({ children, location }) => (
  <div className={"site-content"}>
    <Helmet
      title={'Walter Neubauer'}
      meta={[
        { name: 'description', content: 'Sample' },
        { name: 'keywords', content: 'sample, something' },
      ]}
    />
    <Header />
    <div
      style={{
        flex: '1 0 auto',
        margin: '0',
      }}
    >
      {children}
    </div>
    <Footer />
  </div>
)

export default Layout
