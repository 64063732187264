import React from 'react'
//import Link from 'gatsby-link'
import NavBar from '../NavBar/NavBar'
import LanguagePicker from '../LanguagePicker/LanguagePicker'
import Banner from '../Banner/Banner'
import { Link } from 'gatsby';
import { Location } from '@reach/router';

import './header.module.css';

const Header = () => (
  <div styleName={'header'}>
    <Location>
      {({ location }) => {
        const onENRoute = (location.pathname.indexOf('/en/') !== -1);
        const link = onENRoute ? "/en/" : "/";
        return (
        <Link to={link} styleName={'logoLink'}>
          <div styleName={'logo'}>
            <div styleName={'fontTop'}>
              Walter Neubauer
            </div>
            <div styleName={'fontBottom'}>
              Geigenbaumeister
            </div>
          </div>
        </Link>
      )}}
   </Location>
   {/*<Banner />*/}
    <div styleName={'rightSide'}>
      <div styleName={'languageToggleContainer'}>
        <LanguagePicker />
      </div>
      <NavBar />
    </div>
  </div>
)

export default Header
