import React from 'react'
import { Link } from "gatsby"
import { Location } from "@reach/router"
import classNames from 'classnames'

import './languagepicker.module.css';

const LanguagePicker = (props) => (
  <Location>
    {({ location }) => {
      const path = location.pathname;
      const onENpath = (path.indexOf("/en/") !== -1);
      const enLink = onENpath ? path : `/en${path}`
      const deLink = !onENpath ? path : path.split('/en')[1]
      return (
        <div styleName={'container'}>
          <Link to={deLink} styleName={classNames('option', {'active': !onENpath})}>DE</Link>
          <div styleName='separator'>|</div>
          <Link to={enLink} styleName={classNames('option', {'active': onENpath})}>EN</Link>
        </div>
      )
  }}
  </Location>
)

export default LanguagePicker
