import React from 'react';
//import Popover from 'react-tiny-popover';
import { Link } from "gatsby";
import { Location } from '@reach/router';
import classNames from 'classnames';

import './navbarElement.module.css';

export default class NavBarElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  componentDidMount() {
    //console.log('test');
    // attach event listener for mouse over
  }



  render() {
    return (
      <Location>
        {({location}) => {
          const onENRoute = (location.pathname.indexOf('/en/') !== -1);
          var link = this.props.link;
          if (onENRoute) {
            link = `/en${link}`
          }
          const isActiveRoute = (location.pathname === `${link}/` || location.pathname === `${link}` );
          return (
            <Link
              to={link}
              styleName={classNames('navBarElement', {'active': isActiveRoute})}
            >
            {this.props.label}
          </Link>
        )}}
      </Location>
    );
  }
}


// <Popover
//
//   isOpen={this.state.isOpen}
//   align={'start'}
//   position={['bottom']} // if you-d like, supply an array of preferred positions ordered by priority
//   padding={4} // adjust padding here!
//   onClickOutside={() => this.setState({ isOpen: false })} // handle click events outside of the popover/target here!
//   content={({ position, nudgedLeft, nudgedTop, targetRect, popoverRect }) => ( // you can also provide a render function that injects some useful stuff!
//     <div styleName={'popover'}>
//       <div>
//         Itsa Meee
//       </div>
//     </div>
//   )}
// >
// LINK onClick={() => this.toggle()
// </Popover>
