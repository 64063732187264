import React from 'react';
import NavBarElement from './NavBarElement/NavBarElement';
import { Location } from '@reach/router';

import './navbar.module.css';

const locations = {
  'home': {
    'link': '/',
    'label': {
      'en': 'Home',
      'de': 'Home'
    }
  },
  'tradition': {
    'link': '/tradition',
    'label': {
      'en': 'Tradition',
      'de': 'Tradition'
    }
  },
  'instrumente': {
    'link': '/instrumente',
    'label': {
      'en': 'Instruments',
      'de': 'Instrumente'
    }
  },
  'boegen': {
    'link': '/boegen',
    'label': {
      'en': 'Bows',
      'de': 'Bögen'
    }
  },
  'zubehoer': {
    'link': '/zubehoer',
    'label': {
      'en': 'Accessories',
      'de': 'Zubehör'
    }
  },
  'kontakt': {
    'link': '/kontakt',
    'label': {
      'en': 'Contact',
      'de': 'Kontakt'
    }
  },
};

const NavBar = () => (
    <Location>
      {({ location }) => {
        const onENRoute = (location.pathname.indexOf('/en/') !== -1);
        // Link wird schon automatisch angepasst in NavBarElement
        return (
          <div styleName={'navBar'}>
            {Object.keys(locations).map(e => (
              <NavBarElement
                label={onENRoute ? locations[e].label.en : locations[e].label.de}
                link={locations[e].link}
                key={locations[e].link}
              />
            ))}
          </div>
      )}}
   </Location>
)

export default NavBar
