import React from 'react'
import { Link } from 'gatsby';
import { Location } from '@reach/router';

import './footer.module.css'

import { StaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.lastIndexOf('/', 0) === 0) {
        return (
          <Link to={node.data.uri}>{children}</Link>
        )
      }
      return (
        <a href={node.data.uri}>{children}</a>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: ({ data }) => (
      <img src={`https://${data.target.fields.file.de.url}`} alt={data.target.fields.description.en} />
    )
  }
};

const Footer = () => (
  <div styleName={'footer'}>
    <Location>
      {({ location }) => {
        const onENRoute = (location.pathname.indexOf('/en/') !== -1);
        const i = onENRoute ? 0 : 1;
        return (
          <StaticQuery
            query={footerQuery}
            render={data => (
              <div styleName={'footerContentContainer'}>
                {Object.keys(data.allContentfulFooter.nodes[i]).map(e => (
                  <div
                    styleName={'adressinfo'}
                    key={data.allContentfulFooter.nodes[i][e].id}
                  >
                    {documentToReactComponents(data.allContentfulFooter.nodes[i][e].json, options)}
                  </div>
                ))}
              </div>
            )}
          />
      )}}
    </Location>
    <div styleName={'smallLinksContainer'}>
      <Link to={'/impressum'} styleName={'smallLink'}>Impressum</Link>
      <span styleName={'spacer'}>|</span>
      <Link to={'/datenschutz'} styleName={'smallLink'}>Datenschutz</Link>
    </div>
  </div>
)

export default Footer

const footerQuery = graphql `
  query footerQuery {
    allContentfulFooter {
      nodes {
        adresse {
          json
          id
        }
        oeffnungszeiten {
          json
          id
        }
        kontakt {
          json
          id
        }
        zahlungsmittel {
          json
          id
        }
      }
    }
  }
`
