import React from 'react'
import { Link } from 'gatsby';
import { Location } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import './banner.module.css'

import { StaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.lastIndexOf('/', 0) === 0) {
        return (
          <Link to={node.data.uri}>{children}</Link>
        )
      }
      return (
        <a href={node.data.uri}>{children}</a>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: ({ data }) => (
      <img src={`https://${data.target.fields.file.de.url}`} alt={data.target.fields.description.en} />
    )
  }
};

const enText = "My workshop is open for consulting and repairs from Monday to Thursday from 1:00 to 6:00 p.m. Due to Covid restrictions sales are currently not possible.";
const deText = "Für Beratung und Annahme von Reparaturen ist meine Werkstätte von Montag bis Donnerstag von 13.00 - 18.00 Uhr geöffnet. Der Verkaufsraum ist aufgrund der derzeitigen Covid Maßnahmen vorübergehend geschlossen.";

const Banner = () => (
  <Location>
    {({ location }) => {
      const onENRoute = (location.pathname.indexOf('/en/') !== -1);
      return (
        <div styleName={'banner'}>
          <div styleName={'bannerIconBG'}>
            <div styleName={'bannerIcon'}>
              <FontAwesomeIcon icon={faInfoCircle} color="#fff" />
            </div>
          </div>
            <div styleName={'bannerContent'}>{onENRoute ? enText : deText}</div>
          </div>
    )}}
  </Location>

)

export default Banner
